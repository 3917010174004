<template>
  <div class="p-4">
    <b-field label="게시판 선택">
      <b-select
        v-model="selectedCategory"
        @input="getFroalaData(selectedCategory)"
        class="mb-2"
        placeholder="게시판 선택"
      >
        <option
          v-for="item in category"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></option>
      </b-select>
    </b-field>
    <b-field v-if="selectedCategory === 'projects'" label="프로젝트 분류 선택">
      <b-select v-model="selectedProjectsCategory" placeholder="프로젝트 구분">
        <option
          v-for="item in projectCategory"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></option>
      </b-select>
    </b-field>

    <b-field label="제목">
      <b-input
        v-model="title"
        v-if="isNeedsTitleCategory"
        placeholder="제목을 입력해주세요"
        required
        validation-message="제목을 입력해주세요"
      ></b-input>
    </b-field>
    <froala :config="config" v-model="model"></froala>

    <b-button v-if="selectedCategory" class="mt-2 ml-auto" @click="onSave">저장</b-button>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import client from '../api/client';
import { useRouter } from '../use-router';
import { DialogProgrammatic as Dialog } from 'buefy';

export default defineComponent({
  name: 'Admin',
  setup() {
    const loading = ref(false);
    const title = ref('');
    const selectedCategory = ref('');
    const router = useRouter();
    const category = [
      {
        value: 'about',
        label: 'About mono',
      },
      {
        value: 'main',
        label: 'Main',
      },
      {
        value: 'projects',
        label: 'Projects',
      },
      {
        value: 'process',
        label: 'Process',
      },
      {
        value: 'license',
        label: 'License',
      },
      {
        value: 'press',
        label: 'Press',
      },
    ];
    const selectedProjectsCategory = ref('wholesale');
    const projectCategory = [
      {
        value: 'wholesale',
        label: 'Wholesale',
      },
      {
        value: 'clothing',
        label: 'Clothing',
      },
      {
        value: 'commercial',
        label: 'Commercial',
      },
      {
        value: 'studio',
        label: 'Studio',
      },
      {
        value: 'office',
        label: 'Office',
      },
      {
        value: 'living',
        label: 'Living',
      },
    ];

    const model = ref('');
    const config = computed(() => {
      return {
        language: 'ko',
        height: 1200,
        dragInline: false,
        imageResize: false,
        toolbarButtons: {
          moreText: {
            // List of buttons used in the  group.
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting',
            ],

            // Alignment of the group in the toolbar.
            align: 'left',

            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
            buttonsVisible: 4,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'alignRight',
              'formatOLSimple',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
            align: 'left',
            buttonsVisible: 4,
          },
          moreRich: {
            buttons: ['insertImage', 'insertLink', 'fontAwesome', 'specialCharacters'],
            align: 'left',
            buttonsVisible: 2,
          },
        },
        imageDefaultAlign: 'right',
        imageDefaultWidth: 0,
        imageUploadParams: {
          category: selectedCategory.value,
        },
        imageUploadURL: 'https://themono.co.kr/api/image_upload',
        imageUploadMethod: 'POST',
        imageMaxSize: 1024 * 1024 * 100,
      };
    });

    const isNeedsTitleCategory = computed(
      () =>
        selectedCategory.value === 'projects' ||
        selectedCategory.value === 'press' ||
        selectedCategory.value === 'main'
    );

    async function onSave() {
      loading.value = true;
      if (isNeedsTitleCategory.value) {
        let category = selectedCategory.value;
        if (category === 'main') {
          category = `projects/main`;
        }
        if (category === 'projects') {
          category = `${category}/${selectedProjectsCategory.value}`;
        }
        await client.send(category, title.value.trim(), model.value);
      } else {
        await client.send(selectedCategory.value, 'main', model.value);
      }

      title.value = '';
      model.value = '';
      loading.value = false;

      Dialog.alert(`${selectedCategory.value}에 게시물을 작성했습니다.`);
    }

    async function getFroalaData(category) {
      title.value = '';

      if (isNeedsTitleCategory.value) {
        model.value = '';
        return;
      }

      const result = await client.get(`${category}/main`);
      if (result.data.html) {
        model.value = result.data.html;
      } else {
        model.value = '';
      }
    }

    onMounted(async () => {
      if (router.currentRoute.query.category) {
        selectedCategory.value = router.currentRoute.query.category;
      } else {
        selectedCategory.value = 'about';
      }

      getFroalaData(selectedCategory.value);
    });

    return {
      loading,
      title,
      selectedCategory,
      category,
      projectCategory,
      selectedProjectsCategory,
      getFroalaData,
      isNeedsTitleCategory,

      model,
      config,

      onSave,
    };
  },
});
</script>
